//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TdBtn } from 'td-ui'
import api from '@/api/v3'

export default {
  name: 'ExampleBlock',
  components: {
    TdBtn,
  },
  props: {
    importType: {
      type: String,
      default: 'contacts',
    },
  },
  computed: {
    downloadTemplateURL () {
      switch (this.importType) {
        case 'contacts':
          return api.contacts.getImportTemplateURL()
        case 'tasks':
          return api.tasks.getImportTemplateXLSXURL()
        default:
          return ''
      }
    },
  },
  methods: {
    downloadTemplate () {
      window.open(this.downloadTemplateURL)
    },
  },
}
